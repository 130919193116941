import ContentFooter from "./content-footer.vue"
import SimpleBar from 'simplebar'
import 'simplebar/dist/simplebar.css'

export default {
  // is-active is for mobile version, 
  // to change the right-column's height
  props: ['is-active'],
  components: {
    'content-footer': ContentFooter
  },
  data() {
    return {
      scrolled: false
    };
  },
  watch: {
    // 如果 `isActive` 改變，這個函数就運行
    isActive(newAttr, oldAttr) {
      if(newAttr){
        this.$emit('main-activated', this.$el);
        // scroll to top after changing page
        $(".main-body").scrollTop(0);
      }
    }
  },
  mounted() {
    // use simplebar replace window's default scroll bar
    var el = new SimpleBar($(this.$el).find(".main-body")[0]);

    var self = this;
    var selfEl = this.$el;
    el.getScrollElement().addEventListener('scroll', function() {
      if($(this).scrollTop() > 50){
        console.log(self.scrolled)
        self.scrolled = true;
        $(selfEl).find("header").addClass('scrolled');
        // 避免bg-black-text的transition delay
        $(selfEl).find("header>h1").removeClass('bg-black-text');
      } else {
        self.scrolled = false;
        $(selfEl).find("header").removeClass('scrolled');
        setTimeout(() => {
          $(selfEl).find("header>h1").addClass('bg-black-text');
        }, 1200)
      }
    });
  }
}
