<template>
  <div class="right-column">
    <div :class="{ active: startClean }" id="table-cleaner"></div>
    <homepage-content v-on:main-activated="adjustColumnHeight" :is-active="isHomepage" :class="{ active: isHomepage }"></homepage-content>
    <research-content v-on:main-activated="adjustColumnHeight" :is-active="isResearch" :class="{ active: isResearch }"></research-content>
    <project-content v-on:main-activated="adjustColumnHeight" :is-active="isProject" :class="{ active: isProject }"></project-content>
    <article-content v-on:main-activated="adjustColumnHeight" :is-active="isArticle" :class="{ active: isArticle }"></article-content>
    <news-events-content v-on:main-activated="adjustColumnHeight" :is-active="isNewsEvents" :class="{ active: isNewsEvents }"></news-events-content>
    <news-article-content v-on:main-activated="adjustColumnHeight" :is-active="isNewsArticle" :class="{ active: isNewsArticle }"></news-article-content>
    <contact-content  v-on:main-activated="adjustColumnHeight" :is-active="isContact" :class="{ active: isContact }"></contact-content>
  </div>
</template>

<script>
  import HomepageContent from "./homepage-content.vue"
  import ResearchContent from "./research-content.vue"
  import ProjectContent from "./project-content.vue"
  import ArticleContent from "./article-content.vue"
  import NewsEventsContent from "./news-events-content.vue"
  import NewsArticleContent from "./news-article-content.vue"
  import ContactContent from "./contact-content.vue"

  export default {
    components: {
      'homepage-content': HomepageContent,
      'research-content': ResearchContent,
      'project-content': ProjectContent,
      'article-content': ArticleContent,
      'news-events-content': NewsEventsContent,
      'news-article-content': NewsArticleContent,
      'contact-content': ContactContent
    },
    props: [ 'current-page', 'is-mobile' ],
    data() {
      return {
        startClean: false,
      };
    },
    computed: {
      isHomepage() {
        return !this.startClean && this.currentPage == 'homepage'
      },
      isResearch() {
        return !this.startClean && this.currentPage == 'researches'
      },
      isProject() {
        return !this.startClean && this.currentPage == 'project'
      },
      isArticle() {
        return !this.startClean && this.currentPage == 'article'
      },
      isNewsEvents() {
        return !this.startClean && this.currentPage == 'news-events'
      },
      isNewsArticle() {
        return !this.startClean && this.currentPage == 'news-article'
      },
      isContact() {
        return !this.startClean && this.currentPage == 'contact'
      }
    },
    created() {
      this.$bus.$on('change-page', event => {
        // if page not change
        if(this.currentPage == event)
          return;

        this.startClean = true;
        var self = this
        setTimeout(function () {
          self.startClean = false;
        }, 1000);
      });
    },
    methods: {
      adjustColumnHeight(mainEl) {
        if(this.isMobile){
          $('.right-column').css({height: $(mainEl).height()});
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  $left-column-width: calc(38.2% + 70px);
  $right-column-width: calc(100% - 38.2% - 70px);
  @mixin transition($property) {
    -webkit-transition: $property;
       -moz-transition: $property;
        -ms-transition: $property;
         -o-transition: $property;
            transition: $property;
  }

  .right-column {
    position: relative;
    width: $right-column-width;
    height: 100%;
    overflow: hidden;
  }

  #table-cleaner {
    position: fixed;
    background-color: white;
    width: 100%;
    height: 100%;
    left: 100%;
    top: 0;
    z-index: 30;

    &.active {
      left: calc(38.2% + 70px);
      //slide in in 1 sec
      @include transition(1s ease);
    }
  }

@media screen and (max-width: 768px) {
  .right-column {
    width: calc(100% - 10px);
    margin-top: 25px;
    margin-left: 10px;
    overflow-y: hidden;
    // 220px is left-column's header's height
    // 50px is the menu's
    // 25px is the margin-top
    height: calc(100vh - 220px - 50px - 25px);
  }

  #table-cleaner {
    position: absolute;

    &.active {
      left: 0;
      //slide in in 1 sec
      @include transition(1s ease);
    }
  }
}
</style>
