<template>
  <div id="app">
    <landing-mask v-on:landing-off="landingOff"></landing-mask>
    <udm-menu></udm-menu>
    <left-column :is-mobile="isMobile" :current-page="currentPage"></left-column>
    <right-column :is-mobile="isMobile" :current-page="currentPage"></right-column>
  </div>
</template>

<script>
import LandingMask from './landing-mask.vue';
import UdmMenu from './menu.vue';
import LeftColumn from './left-column.vue'
import RightColumn from './right-column.vue'

export default {
  data: function () {
    return {
      currentPage: '',
      windowWidth: 0
    }
  },
  components: {
    'landing-mask': LandingMask,
    'udm-menu': UdmMenu,
    'left-column': LeftColumn,
    'right-column': RightColumn
  },
  created() {
    // [註冊監聽事件]
    this.$bus.$on('change-page', (event) => {
      this.currentPage = event;
    });

    // scroll to top in mobile version
    this.$bus.$on('change-page', event => {
      if(this.isMobile){
        var body = $("html, body");
        body.stop().animate({scrollTop:0}, 1000, 'swing');
      }
    });
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      });
    })
  },
  computed: {
    isMobile() {
      return this.windowWidth < 769
    }
  },
  methods: {
    landingOff(){
      this.currentPage = "homepage";
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 768px) {
  #app {
    display: block;
    height: auto;
    overflow-x: scroll;
    overflow-y: auto;
  }
}
</style>
