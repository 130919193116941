<template>
  <main id="news-article-content" class="main-content">
    <header class="mobile-info">
      <h2>{{ isNews }}</h2>
    </header>
    <header class="bg-black">
      <h1 class="bg-black-text">{{ article.title }}</h1>
    </header>
    <div class="main-body">
      <article class="bg-black">
        <p class="bg-black-text" v-html="article.article">
        </p>
        <h3 class="bg-black-bordered bg-black-text original-post">
          <a :href="article.original_post_url" target="_blank" rel="noopener noreferrer">
            Original Post
          </a>
        </h3>
      </article>
      <content-footer></content-footer>
    </div>
  </main>
</template>

<script>
  import ContentMixin from "./content-mixin.js"
  import ArticleImg from "../images/news-and-events/[Exp][UDM][news and events][content].jpg"

  export default {
    mixins: [ContentMixin],
    data() {
      return {
        article: {
          title: "",
          article: "",
          original_post: ""
        }
      };
    },
    created() {
      this.$bus.$on('open-news-article', article => {
        this.article = article;
      });
    },
    computed: {
      isNews() {
        if(this.article.title == "")
           return "";
        return (this.article.is_news) ? "News" : "Event";
      }
    }
  }
</script>

<style lang="scss" scoped>
  @mixin transition($property) {
    -webkit-transition: $property;
       -moz-transition: $property;
        -ms-transition: $property;
         -o-transition: $property;
  };
  .main-content {

    .mobile-info {
      display: none;
    }

    header {
      height: 200px;
      margin-bottom: 10px;
    }

    article {
      position: relative;

      .original-post {
        cursor: pointer;
        padding: 9px 24px;
        display: inline-block;
        font-size: 15px;
        margin-top: 20px;
        margin-bottom: 30px;
        @include transition(all 0.5s);
        a {
          color: white;
        }
        &:hover {
          color: black;
          background-color: white;
          @include transition(all 0.5s);
          a {
            text-decoration: none;
            color: black;
          }
        }
      }

      p {
        margin: 0;
      }

      img {
        width: 100%;
      }
    }

  }

  @media screen and (max-width: 768px) {
    .main-content {
      header.mobile-info {
        display: block !important;
        margin: 0 0 10px 0;
        height: auto;
        padding: 20px;
        background-color: #606060;

        h2 {
          color: transparent;
          margin: 0;
        }
      }

      &.active {
        header.mobile-info h2 {
          color: #929292;
          @include transition(color 1s ease 1.2s);
        }
      }
    }
  }

</style>
