<template>
  <div id="left-column">
    <left-column-header :current-page="currentPage" :is-shrink="isShrink"></left-column-header>
    <transition name="fade">
      <left-column-title v-show="!isMobile || projectOpened" v-bind:is-active="projectOpened" :articleOpened="articleOpened" :project-id="projectId"></left-column-title>
    </transition>
    <left-column-article-title v-show="!isMobile || articleOpened" v-bind:is-active="articleOpened"></left-column-article-title>
    <left-column-news-article-title v-if="!isMobile" v-bind:is-active="newsArticleOpened"></left-column-news-article-title>
  </div>
</template>

<script>
  import LeftColumnHeader from "./left-column-header.vue"
  import LeftColumnTitle from "./left-column-title.vue"
  import LeftColumnArticleTitle from "./left-column-article-title.vue"
  import LeftColumnNewsArticleTitle from "./left-column-news-article-title.vue"

  export default {
    props: ['current-page', 'is-mobile'],
    components: {
      'left-column-header': LeftColumnHeader,
      'left-column-title': LeftColumnTitle,
      'left-column-article-title': LeftColumnArticleTitle,
      'left-column-news-article-title': LeftColumnNewsArticleTitle
    },
    data() {
      return {
        projectOpened: false,
        projectId: null,
        articleOpened: false,
        articleId: null,
        newsArticleOpened: false
      }
    },
    computed: {
      isShrink() {
        return this.projectOpened || this.newsArticleOpened
      }
    },
    created() {
      this.$bus.$on('open-project', (id) => {
        this.projectOpened = true
        this.articleOpened = false
        this.projectId = id
      });
      this.$bus.$on('open-article', (event) => {
        this.articleOpened = true
      });
      this.$bus.$on('change-page-by-menu', () => {
        this.articleOpened = false
        this.projectOpened = false
        this.newsArticleOpened = false;
      });
      this.$bus.$on('open-news-article', () => {
        this.newsArticleOpened = true;
      });
    },
  }
</script>

<style lang="scss" scoped>
  #left-column {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 38.2%;
    height: 100%;
    margin-right: 70px;
    position: relative;
  }

  @media screen and (max-width: 768px) {
    #left-column {
      width: 100%;
      margin-top: 50px;
      height: auto;
    }
  }
</style>
