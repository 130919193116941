<template>
  <transition name="fade">
    <div v-if="show" class="bg-black" id="landing-mask">
      <img src="../images/[UDM][logo][WH with 小字].png" alt="">
    </div>
  </transition>
</template>

<script>
  import "../images/[UDM][logo][WH with 小字].png";

  export default {
    data: () => {
      return { show: true };
    },
    mounted: function() {
      var self = this;
      setTimeout(function(){
        self.show = false;
        self.$emit('landing-off');
      }, 3000)
    }
  }
</script>

<style lang="scss" scoped>
#landing-mask {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;

  img {
    width: 320px;
    margin: auto;

  
  }
}

@media screen and (max-width: 768px) {
  #landing-mask {
    height: 100vh;
    img {
      width: 120px;
    }
    z-index: 100;
  }
}
</style>
