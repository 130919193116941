<template>
  <main id="contact-content" class="main-content">
    <header class="bg-black">
      <h1 class="bg-black-text">Contact</h1>
    </header>
    <div class="main-body">
      <form action="/send-contact" method="post">
        <input name="authenticity_token" type="hidden" :value="authToken">
        <label class="title-text" for="name">Name</label>
        <input type="text" name="name">
        <label class="title-text" for="email">Email</label>
        <input type="text" name="email">
        <label class="title-text" for="description">Description</label>
        <textarea type="text" name="description"></textarea>
        <input class="submit_btn" type="submit" value="Submit">
        <h4>UDM</h4>
        <h4>Email:hello@udm.global</h4>
      </form>
      <content-footer></content-footer>
    </div>
  </main>
</template>

<script>
  import ContentMixin from "./content-mixin.js"

  export default {
    mixins: [ContentMixin],
    data(){
      return {
        authToken: null
      }
    },
    mounted() {
      this.authToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  }
</script>

<style lang="scss" scoped>
  header {
    height: 200px;
  }
  h1 {
    margin: 0;
  }
  label {
    padding: 8px 20px;
    font-size: 15px;
  }

  label, input, textarea {
    display: block;
  }

  input, textarea {
    padding-left: 20px;
    border: none;
    color: white;
    font-size: 15px;
    background-color: black;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-border-radius: 0;
    border-radius: 0;
  }
  textarea {
    padding: 20px;
  }

  input {
    height: 45px;
    width: 100%;
  }

  textarea {
    height: 253px;
    width: 100%;
  }

  input.submit_btn {
    font-size: 15px;
    color: white;
    margin-top: 10px;
    display: inline-block;
    padding-right: 20px;
    width: auto;
    margin-bottom: 10px;
  }

  h4 {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 15px;
  }

</style>
