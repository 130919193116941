/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import '../css/main.scss'
import Vue from 'vue'
import App from '../components/app.vue'
import eventBus from '../setup/eventBus'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)


document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('hello'))

  const app = new Vue({
    el,
    data() {
      return {
          // Bind our event bus to our $root Vue model
          bus: eventBus
      }
    },
    render: h => h(App)
  }).$mount()
})
