<template>
  <main class="main-content">
    <header class="bg-black">
      <h1 class="bg-black-text">Research</h1>
    </header>
    <div class="main-body">
      <h2 class="title-text">Projects</h2>
      <div class="projects">
        <template v-for="project in projects">
          <project :project-info="project"></project>
        </template>
      </div>
      <div class="research-footer">
        <content-footer></content-footer>
      </div>
    </div>
  </main>
</template>

<script>
  import ContentMixin from "./content-mixin.js";
  import Project from "./project.vue";

  export default {
    mixins: [ContentMixin],
    components: {
      'project': Project
    },
    data() {
      return {
        projects: null
      };
    },
    methods: {
      getProjects() {
        let self = this;
        this.axios
            .get('/projects.json')
            .then((response) => {
              self.projects = response.data;
            })
      }
    },
    created() {
      this.getProjects();
    }
  }
</script>

<style lang="scss" scoped>
  .main-body {
    .projects {
      margin-bottom: 120px;
    }
  }
  .research-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .research-footer {
      position: static;
    }
  }
</style>
