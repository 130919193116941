<template>
  <main id="project-content" class="main-content">
    <header class="bg-black" :class="scrolled">
      <h1 class="bg-black-text">
        {{ title }}
      </h1>
      <transition name="fade">
        <p :class="{'bg-black-text': !scrolled, 'header-des': scrolled }" v-if="!scrolled">
          {{ description }}
        </p>
      </transition>
    </header>
    <div class="main-body">
      <h2 class="title-text">Articles</h2>
      <div class="articles container-fluid">
        <div class="row">
          <div v-for="article in articles" :key="article.id" class="col-xs-12 col-md-4">
            <div class="article bg-black">
              <h1 class="bg-black-text" @click="openArticle(article)">{{ article.title }}</h1>
            </div>
          </div>
        </div>
      </div>
      <content-footer></content-footer>
    </div>
  </main>
</template>

<script>
  import ContentMixin from "./content-mixin.js"

  export default {
    mixins: [ContentMixin],
    data() {
      return {
        title: null,
        description: null,
        articles: []
      };
    },
    methods: {
      openArticle(id) {
        this.$bus.$emit('open-article', id)
        this.$bus.$emit('change-page', 'article', id)
      },
      adjustArticleHeight() {
        $(".article").css({height: $(".article").width()});
      }
    },
    created() {
      let self = this;

      this.$bus.$on('open-project', id => {
        this.axios
            .get('/project/' + id)
            .then((resp) => {
              self.title = resp.data.title;
              self.description = resp.data.description;
              self.articles = resp.data.udm_articles
            });
      })
    },
    updated() {
      this.adjustArticleHeight();
    }
  }
</script>

<style lang="scss" scoped>
  main {
    header {
      height: auto;
      -webkit-box-sizing: border-box;
         -moz-box-sizing: border-box;
              box-sizing: border-box;

      p {
        line-height: 1.5;
      }

      .header-des {
        color: white;
      }
    }

    .main-body {
      .container-fluid {
        padding: 0;
        .col-4 {
          padding-left: 5px;
          padding-right: 5px;
          &:nth-child(3n + 1) {
            .article {
              padding-left: 30px;
            }
          }
        }
        .article {
          display: flex;
          margin-bottom: 10px;
          h1 {
            font-size: 15px;
            margin: auto;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }


  @media screen and (max-width: 768px) {
    .article h1 {
      font-size: 20px !important;
    }
  }
</style>
