<template>
  <header class="left-header bg-black" :class="{ shrink: isShrink, notHomepage }">
    <transition name="fade" mode="out-in">
      <div>
        <img @click="backTop" class="icon" :src="iconUrl" :key="iconUrl">
        <h1>{{ title }}</h1>
      </div>
    </transition>
    <img v-show="hasImg" class="bg-img" :src="bgImgUrl" :key="bgImgUrl">
  </header>
</template>

<script>
  import HomepageIcon from "../images/[UDM][logo][WH with 小字].png";
  import ResearchIcon from "../images/research/[UDM][icon][research].png";
  import ResearchBg from "../images/research/[UDM][BGI][research].jpg"
  import NewsIcon from "../images/news-and-events/[UDM][icon][news-and events].png";
  import NewsBg from "../images/news-and-events/[UDM][BGI][news-and-events].jpeg";
  import ContactIcon from "../images/contact/[UDM][icon][contact].png";
  import ContactBg from "../images/contact/[UDM][BGI][contact].jpg";

  export default {
    props:[ 'current-page', 'is-shrink' ],
    computed: {
      hasImg() {
        return this.bgImgUrl != ''
      },
      title() {
        switch(this.currentPage) {
          case 'article':
          case 'project':
          case 'researches':
            return 'Research';
          case 'news-events':
          case 'news-article':
            return 'News and Events';
          case 'contact':
            return 'Contact';
          default:
            return '';
        }
      },
      iconUrl() {
        switch(this.currentPage) {
          case 'article':
          case 'project':
          case 'researches':
            return ResearchIcon;
          case 'news-events':
          case 'news-article':
            return NewsIcon;
          case 'contact':
            return ContactIcon
          default:
            return HomepageIcon;
        }
      },
      bgImgUrl() {
        switch(this.currentPage) {
          case 'article':
          case 'project':
          case 'researches':
            return ResearchBg;
          case 'news-events':
          case 'news-article':
            return NewsBg;
          case 'contact':
            return ContactBg;
          default:
            return '';
        }
      },
      notHomepage() {
        return (this.currentPage == "homepage") ? false : true;

      }
    },
    methods: {
      backTop(event) {
        if(event.srcElement.src.includes("research") ||
           event.srcElement.src.includes("news")){
          var page = (event.srcElement.src.includes("research")) ? 'researches' : 'news-events';
          this.$bus.$emit('change-page', page);
          this.$bus.$emit('change-page-by-menu');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @mixin transform($properties) {
    -webkit-transform: $properties;
       -moz-transform: $properties;
        -ms-transform: $properties;
         -o-transform: $properties;
            transform: $properties;
  };

  .left-header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition: height 1s ease;

    & > div {
      position: relative;
      z-index: 5;
      h1 {
        color: white;
        text-align: center;
        font-size: 30px;
      }

      .icon {
        width: 11vw;
        display: block;
        margin: 0 auto;
        margin-bottom: 13px;
        cursor: pointer;
      }
    }

    .bg-img {
      position: absolute;
      height: 100%;
      left: 50%;
      top: 50%;
      @include transform(translateY(-50%) translateX(-50%));
      opacity: 0.3;
    }

    &.shrink {
      height: calc(100% - 160px);
    }
  }

@media screen and (max-width: 768px) {
  .left-header {
    height: 220px;
    position: relative;
    &.shrink {
      height: 220px;
    }
    & > div {
      .icon {
        width: 138px;
      }
    }

    &.notHomepage > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 78px;
      .icon {
        width: 76px;
        margin: 0 21px 0 0;
      }

      h1 {
        font-size: 30px;
        margin: 0;
      }
    }

    .bg-img {
      width: 100%;
    }
  }
}
</style>
