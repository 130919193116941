<template>
  <main id="news-events-content" class="main-content">
    <header class="bg-black">
      <h1 class="bg-black-text">News and Events</h1>
    </header>
    <!-- simplebar跟v-for合不來，需手動打好結構 -->
    <div data-simplebar class="main-body">
      <div class="simplebar-scroll-content">
        <div class="simplebar-content">
          <template v-for="newsEvent in news_and_events">
            <NewsEvent :newsEvent="newsEvent" :key="newsEvent.title"></NewsEvent>
          </template>
          <content-footer></content-footer>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import ContentMixin from "./content-mixin.js"
  import NewsEvent from "./news-event.vue"

  export default {
    mixins: [ ContentMixin ],
    components: { NewsEvent },
    data() {
      return {
        news_and_events: []
      }
    },
    created() {
      // load all news_events
      this.axios
          .get("/news_events.json")
          .then((resp) => {
            this.news_and_events = resp.data;
          });
    }
  }
</script>
