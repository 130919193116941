<template>
  <div :class="{ active: isActive }">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
  export default {
    props: ['isActive'],
    data() {
      return {
        title: ""
      }
    },
    created() {
      this.$bus.$on('open-article', article => {
        this.title = article.title;
      });
    },
  }
</script>

<style lang="scss" scoped>
  @mixin transition($property) {
    -webkit-transition: $property;
       -moz-transition: $property;
        -ms-transition: $property;
         -o-transition: $property;
            transition: $property;
  }

  @mixin box-sizing($property) {
    -webkit-box-sizing: $property;
       -moz-box-sizing: $property;
            box-sizing: $property;
  }

  div {
    display: flex;
    background-color: #606060;
    width: 0;
    height: 150px;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: 0;

    @include transition(all 1s);
    @include box-sizing(border-box);

    h1 {
      margin: auto;
      font-size: 15px;
      color: #606060;
    }
  }

  div.active {
    width: 150px;
    padding: 20px;
    @include transition(all 1s);

    h1 {
      color: white;
      @include transition(color 1s ease 2.2s);
    }
  }

  @media screen and (max-width: 768px) {
    div {
      margin-left: 10px;
      margin-top: 10px;
      display: inline-block;
      position: static;
    }
    div.active {
      width: 120px;
      h1 {
        color: #929292;
      }
    }
  }
</style>
