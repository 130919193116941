<template>
  <div class="left-column-title" :class="{ active: isActive, articleOpened: articleOpened }">
    <!-- title-container is for positioning aside-info -->
    <div class="title-container"> 
      <h1 @click="openProject">{{ title }}</h1>
      <h3 class="web-info">Principal Investigator: {{ investigator }}</h3>
      <h3 class="web-info">Co-Lead: {{ coLead }}</h3>
      <transition name="fade">
        <div v-show="!articleOpened" class="aside-info">
          <div class="project-logos">
            <img :src="logoUrl" alt="">
          </div>
          <h3>{{ articlesCount }} Article{{ addS }}</h3>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import LeftColumnTitle from "./left-column-title.vue"

  export default {
    props: ['isActive', 'articleOpened', 'projectId'],
    data() {
      return {
        title: "",
        investigator: "",
        coLead: "",
        articlesCount: 0,
        logoUrl: "" 
      };
    },
    methods: {
      openProject() {
        this.$bus.$emit('change-page', 'project')
        this.$bus.$emit('open-project', this.projectId)
      }
    },
    computed: {
      addS() {
        return (this.articlesCount > 1) ? "s" : "";
      }
    },
    watch: {
      projectId() {
        this.axios
            .get("/project/" + this.projectId)
            .then((resp) => {
              this.title = resp.data.title;
              this.investigator= resp.data.principal_investigator;
              this.coLead= resp.data.co_lead;
              this.articlesCount = resp.data.articles_count;
              this.logoUrl = resp.data.logo.url;
            });
      }
    }
  }
</script>

<style lang="scss" scoped>
  @mixin transition($property) {
    -webkit-transition: $property;
       -moz-transition: $property;
        -ms-transition: $property;
         -o-transition: $property;
            transition: $property;
  }

  .left-column-title {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    width: 100%;
    margin-right: 70px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    height: 160px;
    background-color: #606060;
    padding: 20px;

    .title-container {
      position: relative;
    }

    h1 {
      margin: 0;
      color: transparent;
      font-size: 20px;
      font-weight: bold;
      width: 55%;
      margin-bottom: 5px;
      cursor: pointer;
      @include transition(all 1s);
      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      font-size: 12px;
      margin: 0;
      color: transparent;
    }

    &.active {
      top: calc(100% - 160px);
      transition: all 1s ease ;
      h1 {
        color: white;
        @include transition(color 1s ease 2.2s);
      }
      h3 {
        color: #929292;
        @include transition(color 1s ease 2.2s);
      }
    }

    &.articleOpened {
      width: calc(100% - 160px);
      h1 {
        width: 100%;
        @include transition(width 1s);
      }
    }

    .aside-info {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .project-logos {
        img {
          width: 80px;
          height: 80px;
        }
        opacity: 0;
      }

      h3 {
        width: 100%;
        text-align: right;
      }
    }

    &.active {
      .aside-info {
        .project-logos{
          opacity: 0.4;
          @include transition(all 1s ease 2.2s);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .left-column-title {
      position: static;
      margin: 10px 0 0 10px;
      height: 150px;

      h1 {
        font-size: 15px;
        font-weight: normal;
      }
      &.active h1 {
        color: #929292;
      }

      // use 
      .aside-info {
        height: 110px;
      }

      .project-logos {
        width: 60px;
        height: 60px;
      }

      .web-info {
        display: none;
      }

      &.articleOpened {
        width: calc(100% - 140px);
      }
    }
  }
</style>
