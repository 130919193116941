<template>
  <div class="bg-black project">
    <h2 class="bg-black-text" v-on:click="openProject">
      {{ projectInfo.title }}
    </h2>
    <p class="bg-black-text">
      {{ truncateDes }}
    </p>
    <h5 class="bg-black-gray-text">{{ projectInfo.articles_count }} Article{{ showS }}</h5>
  </div>
</template>

<script>
  export default {
    props: ['project-info'],
    methods: {
      openProject() {
        this.$bus.$emit('open-project', this.projectInfo.id);
        this.$bus.$emit('change-page', 'project', this.projectInfo.id);
      }
    },
    computed: {
      truncateDes() {
        return this.projectInfo.description.split(" ").splice(0, 30).join(" ") + "...";
      },
      showS() {
        return (this.projectInfo.articles_count > 1)? "s" : "";
      }
    }
  }
</script>

<style lang="scss" scoped>
  .project {
    padding: 20px;
    padding-bottom: 6px;

    h2 {
      margin: 0;
      font-size: 25px;
      font-weight: bold;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    p {
      font-size: 15px;

      margin-top: 14px;
      margin-bottom: 24px;
    }
    h5 {
      font-size: 15px;
    }
  }

</style>
