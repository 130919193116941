<template>
  <main id="homepage-content" class="main-content">
    <header class="bg-black">
      <h1 class="bg-black-text">About UDM</h1>
    </header>
    <div class="main-body">
      <h2 class="title-text">Institute of Urban Diversity & Mobility</h2>
      <article class="bg-black bg-black-text">
        UDM is a global academic institute currently based in Taiwan. The mission of UDM is to study how people and cities interact in a rapidly urbanising world, focusing on how the development of cities impacts on society, culture and the environment through close collaborations with Taiwan Govt. and international academic institutions. UDM aims to shape new thinking and practice on how to make cities smarter and more sustainable for the next generation of urban dwellers through a series of technology and innovation programmes.
      </article>
      <h2 class="title-text">member</h2>
      <member></member>
      <content-footer></content-footer>
    </div>
  </main>
</template>

<script>
  import Member from './member.vue';
  import ContentMixin from "./content-mixin.js"

  export default {
    mixins: [ContentMixin],
    components: {
      'member': Member
    }
  }
</script>

<style lang="scss" scoped>
  article {
    font-size: 15px;
    padding: 20px 26px 40px 20px;
    line-height: 1.6;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

</style>
