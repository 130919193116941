<template>
  <div id="menu-group">
    <div id="hamburgar" @click="showMenu" v-bind:class="{ open: show }">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <transition name="fade">
      <menu v-show="show">
        <aside>
          <transition name="fade" mode="out-in">
            <img :src="asideIconUrl" :key="asideIconUrl">
          </transition>
        </aside>
        <div id="menu-right">
          <nav>
            <h3 v-for="nav in navs"
                v-on:mouseover= "mouseOver"
                v-on:mouseleave= "mouseLeave"
                @click="changePage"
                :name="nav.name">
              {{ nav.message }}
            </h3>
          </nav>
        </div>
      </menu>
    </transition>
  </div>
</template>

<script>
  import MenuIcon from "../images/[UDM][icons][menu][menu].png"
  import HomepageIcon from "../images/[UDM][logo][WH with 小字].png";
  import ResearchIcon from "../images/research/[UDM][icon][research].png";
  import NewsIcon from "../images/news-and-events/[UDM][icon][news-and events].png";
  import ContactIcon from "../images/contact/[UDM][icon][contact].png";


  var data = {
    show: false,
    asideIconUrl: MenuIcon,
    navs: [
      { name: 'homepage',   message: 'Homepage'},
      { name: 'researches', message: 'Research'},
      { name: 'news-events',       message: 'News and Events'},
      { name: 'contact',    message: 'Contact'}
    ],

    iconUrls: [
      { name: 'homepage', url: HomepageIcon },
      { name: 'researches', url: ResearchIcon },
      { name: 'news-events', url: NewsIcon },
      { name: 'contact', url: ContactIcon}
    ]

  };
  export default {
    data: () => {
      return data
    },
    methods: {
      showMenu: function() {
        this.show = !this.show;
      },

      mouseOver: function(event) {
        this.asideIconUrl = this.iconUrls[this.findUrl(event.target.attributes.name.value)].url
      },

      findUrl: function(name) {
        for(var i = 0; i < this.iconUrls.length; i++){
          if(this.iconUrls[i].name == name)
            return i;
        }
      },

      mouseLeave: function(event) {
        this.asideIconUrl = MenuIcon
      },

      changePage(event) {
        this.$bus.$emit('change-page', event.target.attributes.name.value);
        this.$bus.$emit('change-page-by-menu');
        this.showMenu();
      }
    }
  }
</script>

<style lang="scss" scoped>
  #menu-group {
    position: absolute;
    left: 0;
    top: 0;
  }

  menu {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(black, 0.9);
    z-index: 20;
    margin: 0;
    padding: 0;
    display: flex;

    aside {
      width: 38.2%;
      height: 100%;
      margin-right: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 11vw;
      }
    }

    #menu-right {
      height: 100%;
      width: calc(100% - 38.2% - 70px);
      display: flex;
      align-items: center;

      nav {
        padding-left: 22px;
        h3 {
          color: white;
          cursor: pointer;
          font-size: 30px;
          margin-bottom: 18px;
        }
      }
    }
  }



  #hamburgar{
    z-index: 25;
    width: 33px;
    height: 35px;
    position: relative;
    left: 26px;
    top: 31px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  #hamburgar span{
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #hamburgar span:nth-child(1) {
    top: 0px;
  }

  #hamburgar span:nth-child(2),#hamburgar span:nth-child(3) {
    top: 10px;
  }

  #hamburgar span:nth-child(4) {
    top: 20px;
  }

  #hamburgar.open span:nth-child(1) {
    top: 20px;
    width: 0%;
    left: 50%;
  }

  #hamburgar.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #hamburgar.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #hamburgar.open span:nth-child(4) {
    top: 20px;
    width: 0%;
    left: 50%;
  }

  @media screen and (max-width: 768px) {
    #menu-group {
      position: fixed;
      height: 50px;
      width: 100%;
      z-index: 50;
      background-color: white;
    }
    #hamburgar {
      position: fixed;
      left: 11px;
      top: 14px;
      height: 23px;
    }
    #hamburgar span{
      background-color: black;
    }

    #hamburgar.open span{
      background-color: white;
    }

    menu {
      flex-direction: column;
      aside {
        width: 100%;
        align-items: flex-end;
        img {
          width: 120px;
        }
      }

      #menu-right {
        width: 100%;
        padding-top: 45px;
        align-items: flex-start;
        nav {
          width: 100%;
          padding: 0;
          h3 {
            margin-top: 0;
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }
  }
</style>
