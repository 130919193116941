<template>
  <footer class="content-footer bg-black">
    <div @click="goHome" class="logo">
      <img src="../images/[UDM][logo][WH wo 小字].png" alt="udm-logo">
    </div>
    <div class="copyright bg-black-text">
      <h5>2018 UDM CopyRight</h5>
    </div>
    <div class="email bg-black-text">
      <h5>E-mail: hello@udm.global</h5>
    </div>
  </footer>
</template>

<script>
  import "../images/[UDM][logo][WH wo 小字].png"

  export default {
    methods: {
      goHome() {
        this.$bus.$emit('change-page', 'homepage');
        this.$bus.$emit('change-page-by-menu');
      }
    }
  }
</script>

<style lang="scss" scoped>
  footer {
    margin-top: 20px;
    display: flex;
    width: 100%;
    height: 160px;
    & > div {
      flex: 1;
    }
    .logo {
      cursor: pointer;
      img {
        width: 107px;
      }
    }

    .copyright {
      align-self: flex-end;
      text-align: center;
      margin-bottom: 10px;
    }

    .email {
      text-align: right;
      padding-top: 30px;
      h5 {
        font-size: 15px;
      }
    }

    h5 {
      margin: 0;
      font-size: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    footer {
      height: 200px;
      flex-direction: column;
      .logo {
        order: 1;
      }

      .email {
        order: 2;
        text-align: left;
        padding-top: 10px;
      }

      .copyright {
        order: 3;
        margin: 0;
        align-self: center;
        display: flex;
        align-items: flex-end;
        padding-bottom: 10px;
        h5 {
          align-self: flex-end;
          font-size: 8px;
        }
      }
    }
  }
</style>
