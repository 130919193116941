<template>
  <div class="left-column-news-article-title" :class="{ active: isActive }">
    <h2 class="category">{{ cat }}</h2>
    <h1>{{ title }}</h1>
    <h2 class="date">{{ date }}</h2>
  </div>
</template>

<script>
  import TimeMethods from "./time-methods.js"

  export default {
    mixins: [TimeMethods],
    props: ['isActive'],
    data() {
      return {
        cat: "News",
        title: "",
        date: ""
      }
    },
    created() {
      this.$bus.$on('open-news-article', article => {
        this.cat = (article.is_news) ? "News" : "Event";
        this.title = article.title;
        this.date = this.formatDate(new Date(article.release_date));
      });
    },
  }
</script>

<style lang="scss" scoped>
  @mixin transition($property) {
    -webkit-transition: $property;
       -moz-transition: $property;
        -ms-transition: $property;
         -o-transition: $property;
            transition: $property;
  }

  .left-column-news-article-title {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    width: 100%;
    margin-right: 70px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    height: 160px;
    background-color: #606060;
    padding: 20px;

    h1 {
      margin-top: 13px;
      margin-bottom: 23px;
      color: transparent;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      @include transition(all 1s);
    }

    h2 {
      color: transparent;
      font-size: 15px;
      margin: 0;
    }

    &.active {
      top: calc(100% - 160px);
      transition: all 1s ease ;
      h1 {
        color: white;
        @include transition(color 1s ease 2.2s);
      }

      h2 {
        color: #929292;
        @include transition(color 1s ease 2.2s);
      }
    }
  }
</style>
