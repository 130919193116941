<template>
  <main id="article-content" class="main-content">
    <header class="bg-black">
      <h1 class="bg-black-text">{{ title }}</h1>
    </header>
    <div class="main-body">
      <div class="info">
        <div class="author bg-black">
          <h4 v-for="author in authors" :key="author" class="bg-black-text">
            {{ author }}
          </h4>
          <h4 class="bg-black-text">{{ releaseDate }}</h4>
        </div>
        <div class="tags bg-black">
          <h4 v-for="tag in tags" :key="tag" class="bg-black-text">
            # {{ tag }}
          </h4>
        </div>
      </div>
      <div class="bg-black">
        <article class="bg-black-text" v-html="article"></article>
      </div>
      <content-footer></content-footer>
    </div>
  </main>
</template>

<script>
  import ContentMixin from "./content-mixin.js";
  import TimeMethods from "./time-methods.js";

  export default {
    mixins: [ContentMixin, TimeMethods],
    data() {
      return {
        title: "",
        authors: [],
        releaseDate: "",
        tags: [],
        article: ""
      };
    },
    created() {
      this.$bus.$on('open-article', article => {
        this.title = article.title;
        this.authors = article.author.split(", ");
        this.releaseDate = this.formatDate(new Date(article.release_date));
        this.tags = article.tags.split(", ");
        this.article = article.article;
      });
    }
  }
</script>

<style lang="scss" scoped>
  .main-content {
    header {
      height: 160px;
    }

    h4 {
      font-size: 15px;
      margin: 0;
      line-height: 1.5;
    }

    .info {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;

      & > div {
        width: calc((100% - 10px) / 2 );
        height: 140px;
      }

      article {
        padding-bottom: 100px;
      }
    }
  }

</style>
