<template>
  <div class="news-event">
    <h3 class="title-text">{{ category }}</h3>
    <div class="bg-black">
      <h2 class="bg-black-text link" @click="openNews">{{ newsEvent.title }}</h2>
      <p class="bg-black-text">{{ newsEvent.introduction }}</p>
      <h5 class="bg-black-gray-text">{{ formatDate(new Date(newsEvent.release_date)) }}</h5>
    </div>
  </div>
</template>

<script>
  import TimeMethods from "./time-methods.js";

  export default {
    mixins: [TimeMethods],
    props: ['newsEvent'],
    methods: {
      openNews(event) {
        this.$bus.$emit('change-page', 'news-article');
        this.$bus.$emit('open-news-article', this.newsEvent);
      }
    },
    computed: {
      category() {
        return (this.newsEvent.is_news) ? "News" : "Event";
      }
    }
  }
</script>

<style lang="scss" scoped>
  .news-event {
    h3 {
      margin: 5px 0 5px 20px;
    }
  
    h2 {
      margin: 0;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 15px;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 27px;
    }
  
    h5 {
      font-size: 15px;
      margin: 0;
    }
  }
</style>
