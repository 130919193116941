<template>
  <div id="member">
    <div class="member_names bg-black">
      <div data-simplebar class="member-container">
        <div v-for="member in members"
            :key="member.name"
            v-on:mouseover="showIntro"
            v-on:mouseleave="mouseLeave"
            class="member_title">
          <h5 class="bg-black-text">{{ member.name }}</h5>
          <h6 class="bg-black-gray-text">{{ member.position }}</h6>
        </div>
      </div>
    </div>
    <div class="member_pic bg-black">
      <transition name="fade">
        <div class="intro" v-if="isActive">
          <div class="member_title">
            <h5 class="bg-black-text">{{ selectedMember.name }}</h5>
            <h6 class="bg-black-gray-text">{{ selectedMember.position }}</h6>
          </div>
          <ul>
            <li 
              v-for="exp in selectedMember.experiences"
              :key="exp"
              class="experience bg-black-text"
            >
              {{ exp }}
            </li>
          </ul>
        </div>
      </transition>
      <img src="../images/homepage/[Exp][UDM][D-M][maps].gif">
    </div>
  </div>
</template>

<script>
  import "../images/homepage/[Exp][UDM][Materials][homepage][member].png"
  import "../images/homepage/[Exp][UDM][D-M][maps].gif"
  import 'simplebar'
  import 'simplebar/dist/simplebar.css'

  export default {
    data() {
      return {
        selectedMember: {name: "", position: "", experiences: []},
        isActive: false,
        members: [
          {
            name: 'Chih-Wei Chen',
            position: 'Chair',
            experiences: [
              'Member of National Council for Sustainable Development, the Executive Yuan, Taiwan Govt.',
              'Research Adviser of Taiwan Geographic Information System Center (TGIC)',
            ]
          },
          {
            name: 'Chia Chieh Lee',
            position: 'Director, Design Division',
            experiences: [
              'Founder of in-co Studio',
              'Former General Manager of Innovation Open House (IOH)'
            ]
          },
          {
            name: 'C.J. Anderson-Wu',
            position: 'Director, Communication Division',
            experiences: [
              'Special Editor of Taiwan Architect Magazine'
            ]
          },
          {
            name: 'Peter Bishop',
            position: 'Honorary Research Fellow',
            experiences: [
              "Professor of Urban Design, Bartlett School of Architecture, University College London",
              "Director of Allies and Morrison"
            ]
          },
          {
            name: 'Konrad Otto-Zimmermann',
            position: 'Honorary Research Fellow',
            experiences: [
              "Creative Director of The Urban Idea",
              "The former Secretary General of ICLEI – Local Governments for Sustainability"
            ]
          },
          {
            name: 'Ekim Tan',
            position: 'Honorary Research Fellow',
            experiences: ["Founder and Director of Play the City"]
          },
          {
            name: 'Nicholas A. Phelps',
            position: 'Senior Research Fellow',
            experiences: [
              "Professor and Chair of Urban Planning, Melbourne School of Design, University of Melbourne",
              'The former Professor of Urban and Regional Development, Bartlett School of Planning, University College London',
              'The former Pro Vice Provost International (Southeast Asia), University College London'
            ]
          },
          {
            name: 'Li-Wei Liu',
            position: 'Senior Research Fellow',
            experiences: [
              'Professor of Urban Planning and Spatial Information, Feng Chia University',
              'Director of Center for Urban and Rural Development'
            ]
          },
          {
            name: 'Yaw-Hwa Liou',
            position: 'Senior Research Fellow',
            experiences: [
              'Associate Professor of Urban Planning and Spatial Information, Feng Chia University'
            ]
          },
          {
            name: 'Chieh-Hsiang Wu',
            position: 'Senior Research Fellow',
            experiences: [
              'Associate Professor of Fine Arts, National Changhua University of Education'
            ]
          },
          {
            name: 'Julie Tian Miao',
            position: 'Senior Research Fellow',
            experiences: [
              "Senior Lecturer of Real Estate and Economic Development, Melbourne School of Design, University of Melbourne",
              "The former Lecturer of Urban Planning and Development, Department of Urban Studies, University of Glasgow"
            ]
          },
          {
            name: 'Chine-Hung Tung',
            position: 'Senior Research Fellow',
            experiences: [
              'Assistant Professor of Landscape and Recreation, National Chung-Hsing University'
            ]
          }
        ]
      };
    },

    methods: {
      setMember(name) {
        for (var i = 0; i < this.members.length; i++) {
          if(this.members[i].name == name)
            this.selectedMember = this.members[i];
        }
      },
      showIntro(event) {
        this.isActive = true
        this.setMember(event.target.innerText)
      },
      mouseLeave() {
        this.isActive = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  $gray: #929292;
  @mixin transition($property) {
    -webkit-transition: $property;
       -moz-transition: $property;
        -ms-transition: $property;
         -o-transition: $property;
            transition: $property;
  }

  .member_title {
    h5 {
      font-size: 15px;
      margin: 0;
      line-height: 1.3;
    }

    h6 {
      margin: 0;
      margin-top: 4px;
      line-height: 1.3;
      font-size: 12px;

    }
  }

  #member {
    display: flex;

    .member_names {
      display: inline-block;
      width: 37.2%;
      height: 36vw;
      margin-right: 7px;
      padding: 20px 0 20px 20px;
      -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
      overflow: hidden;
      .member_title {
        cursor: pointer;
        margin-bottom: 15px;
        &:hover h5 {
          text-decoration: underline;
        }
      }

      .member-container {
        height: 36vw;
        padding-bottom: 20px;
      }
    }

    .member_pic {
      padding: 0;
      height: 36vw;
      display: inline-block;
      width: calc(62.8% - 7px);
      background-color: black;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        opacity: 0.4;
      }

      .intro {
        position: absolute;
        padding: 20px;
        background-color: black;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
        @include transition(all 1s ease);

        ul {
          margin: 0;
          margin-top: 35px;
          list-style-type: none;
          padding: 0;
          li.experience {
            font-size: 15px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

@media screen and (max-width: 768px){
  #member {
    flex-direction: column;
    margin-bottom: 40px;
    .member_names {
      width: 100%;
      height: 250px;
      margin-bottom: 10px;
      .member-container {
        height: 250px;
      }
    }


    .member_pic {
      width: 100%;
    }
  }
}
</style>
